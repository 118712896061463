<template>
  <div id="app" class="app">
    <router-view class="app-main" />
    <AppFooter />
  </div>
</template>
<script>
import AppFooter from "./components/AppFooter";
export default {
  components: { AppFooter },
};
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app-main {
  flex: 1;
}
</style>
