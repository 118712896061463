import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["token", "userInfo"],
    }),
  ],
  state: {
    userInfo: {
      username: "",
      id: "",
    },
    token: "",
    mode: "order",
    nodes: [],
    physicalNodes: [],
    poolNodes: [],
    soloDailyStats: {
      cheques: 0,
      gbzz: 0,
    },
    order: {},
    showMainNetData: true,
    revenueRecord: [],
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, info) {
      state.userInfo = info;
    },
    setNodes(state, info) {
      state.nodes = info;
    },
    setPhysicalNodes(state, physicalNodes) {
      state.physicalNodes = physicalNodes;
    },
    setPoolNodes(state, info) {
      state.poolNodes = info;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setSoloDailyStats(state, stats) {
      state.soloDailyStats = stats;
    },
    logout(state) {
      state.token = "";
      state.userInfo = {
        username: "",
        id: "",
      };
    },
    setOrderData(state, data) {
      state.order = data;
    },
    setIsMainNet(state, status) {
      state.showMainNetData = status;
    },
    setRevenueRecord(state, data) {
      state.revenueRecord = data;
    },
    setRevenueRecordFIL(state, data) {
      state.revenueRecordFIL = data;
    },
  },
  actions: {},
  modules: {},
});
