<template>
  <footer class="footer"></footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
}
</style>
