import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "forget-password" */ "../views/ForgetPassword.vue"
      ),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/Registration.vue"
      ),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../views/ChangePassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/wallet-binding",
    name: "WalletBinding",
    component: () =>
      import(
        /* webpackChunkName: "wallet-binding" */ "../views/WalletBinding.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
